body {
  background: #202629;
}
#searchCity{
  color: #fff;
  text-align: center;
  font-size: 2rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 100px;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}




/* Weather */
.weather.card {
  margin-top:4vh;
}

.weather.card .card-content {
  background: #0b0829;
}

.weather.card .card-action {
  background: #120944;
}

.weather .temperature {
  margin-left:4vh;
  font-size:120px;
  font-family: 'Roboto', sans-serif;
  text-shadow: -1px 0 #61dafb, 0 1px #61dafb, 1px 0 #61dafb, 0 -1px #61dafb;
}


.test{
  background-color: #fff;
  color: #008;
}